.accordion {
  background: #f3f3f3;
  border: 1px solid #979797;
  margin-top: $s0;

  + .accordion {
    margin-top: 0;
  }

  .accordion-header-section {
    background: white;
    padding: $s0 $s1;
    display: flex;
    align-items: center;
    max-width: 100%;
    cursor: pointer;
  }

  .accordion-header-section + .accordion-content[data-hide] {
    border-top: none;
  }

  .accordion-title {
    font-weight: bold;
    margin-right: auto;
  }

  .accordion-content {
    padding: 0 $s1;
    border-top: 1px solid #979797;

    > *:first-child {
      margin-top: $s1;
    }

    > *:first-child.accordion{
      margin-top: 0;
      border-top: none;
    }

    > *:last-child:not(.accordion) {
      margin-bottom: $s1;
    }

    .accordion-header-section {
      background: #f3f3f3;
    }
  }

  .accordion {
    margin-left: -$s1;
    margin-right: -$s1;
    border-left: none;
    border-right: none;

    &:last-child {
      border-bottom: none;
    }
  }

  @mixin depth-padding($depth: 1) {
    .accordion-header-section {
      padding-left: $s0 * $depth;
    }

    .accordion-content {
      padding-left: $s0 * $depth;

      .accordion {
        margin-left: -$s0 * $depth;
      }
    }
  }

  //level padding
  .accordion {
    @include depth-padding(2);
  }

  .accordion .accordion {
    @include depth-padding(3);
  }
}

