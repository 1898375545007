.dev-banner {
  background: #000;
  color: #fff;
  padding: 0.8rem 2rem;
  max-width: unset;
  position: relative;
  z-index: 50;
  display: flex;
  justify-content: center;
}

.rails-environment-development {
  background: #666;
}

.rails-environment-uat {
  background: #900;
}
