.hang {
  display: flex;
  justify-content: space-between;
}

.hang-center {
  align-items: center;
}

.hang-right {
  & > *:first-child {
    flex-shrink: 1;
    flex-grow: 1;
  }
  & > *:last-child {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 1rem;
  }
}

.hang-left {
  & > *:first-child {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 1rem;
  }
  & > *:last-child {
    flex-shrink: 1;
    flex-grow: 1;
  }
}
