$header-breakpoint: 1100px;

header[role=banner] {
  width: auto;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #000;
  position: relative;
  background: #fff;
}

// Push the nav to the right

header[role=banner] nav {
  margin-left: auto;
}

// Logo

header[role=banner] .logo {
  display: block;
  padding-right: 1rem;
  margin-left: 1.5rem;

  svg {
    width: scaling-size(125, 178, px);
    height: scaling-size(30, 46, px);
    display: block;
  }
}

header[role=banner] .logo img {
  max-width: 100%;
  max-height: 100%;
}

