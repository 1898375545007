.sbs-image {
  display: flex;
  min-height: 440px;
  height: 100%;
  background-size: cover;
  background-position: center;
  max-width: unset;
}

.sbs-image .icon-large {
  display: block;
  margin: auto;
  width: 2.5em;
  height: 2.5em;
}

.sbs-content-component {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "image content";
}

.sbs-content-component + .sbs-content-component {
  margin-top: 0
}

.sbs-content-component .sbs-section {
  width: 100%;
}

@media screen and (max-width: $site-width) {
  .sbs-content-component,
  .sbs-content-component.image-right {
    grid-template-columns: 100%;
    grid-template-areas: "image" "content";
  }
}

.sbs-content {
  align-self: center;
  grid-area: content;
}

.sbs-content .trix-content {
  padding: 1rem 5.3125rem 1rem 1.85rem;
}

.image-right {
  grid-template-areas: "content image";

  .trix-content {
    padding-left: 5.3125rem;
    padding-right: 1.85rem;
  }
}

@media screen and (max-width : $site-width) {
  .sbs-content .trix-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.sbs-content-component__remove-margins {
  margin: 0 !important;
}

