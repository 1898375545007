@media print {

  // hiding irrelevant elements
  header[role=banner],
  .footer {
    display: none;
  }

  // HTML5 Boilerplate print enhancements
  // https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .print--break-before {
    page-break-before: always;
  }

  .print--break-after {
    page-break-after: always;
  }
}
