multi-select {
  display: block;
  position: relative;
  min-width: 18%;
  line-height: 44px;

  > .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    width: 100%;
    color: black;
    background: $field-background-color;
    padding: 0.375rem 1.25rem;
    border-radius: $border-radius;
    border: 2px solid transparent;
  }

  > .placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  > .value::after {
    position: absolute;
    top: 50%;
    right: 0.75em;
    display: block;
    content: '';
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
    border-width: 5px;
    margin-top: -2.5px;
    pointer-events: none;
  }

  &:not([open]) > .value::after {
    border-top-color: #000;
  }

  [role=listbox] {
    display: none;
    list-style: none;
    padding-left: 0;
  }

  &[open] [role=listbox] {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    width: 100%;
    overflow-y: auto;
    border-top: none;
    background: #f3f3f3;
    box-shadow: 0 3px 15px 0 #00000030;
  }

  [role=option] {
    outline-offset: -4px;
    position: relative;
    padding: 0.5rem 1.25rem;
    background: #f3f3f3;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 1.5rem;
  }

  [role=option]:last-child {
    border-bottom: none;
  }

  [role=option]:hover {
    background-color: rgba(0, 0, 0, .2);
  }

  [role=option][selected] {
    padding-right: 22px;
  }

  [role=option][selected]::after {
    position: absolute;
    right: 0.5em;
    content: '✔';
    color: #000;
  }
}
