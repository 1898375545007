$measure: 70ch;
$site-width: 65rem;

/* Based of line-height of 1.375 from https://bbc.github.io/gel/foundations/typography/ */
$sh: 0.5rem;
$st: 0.75rem;
$s0: 1rem;
$s1: 1.375rem;
$s2: 1.89rem;
$s3: 2.6rem;
$s4: 3.57rem;
$s5: 4.91rem;
$s6: 6.76rem;

$font-color: #000;
$color-error: #d4351c;
$color-success: #00823B;
$color-light-grey: #f3f2f1;
$color-mid-grey: #b1b4b6;
$color-dark-grey: #626a6e;

$border-thin: 1px;
$border-radius: 4px;

$field-background-color: #E7E7E7;
