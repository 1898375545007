.cta-buttons {
  z-index: 10;
  margin-top: -100px;

  .button {
    font-weight: normal;
    border: 2px solid $color-primary;
    border-radius: $border-radius;
    min-height: calc(144px - 3em);
    padding: 1.5em;

    &:hover {
      background-color: $color-primary;
      color: #000;

      svg {
        fill: none;
        stroke: #000;

        &.icon-fill {
          fill: #000;
          stroke: none;
        }
      }
    }
  }

  h3 {
    font-size: 1.25rem;
  }

  .with-icon {
    align-items: flex-start;
    display:flex;
    height:100%;

    svg {
      fill: none;
      stroke: #000;

      &.icon-fill {
        fill: #000;
        stroke: none;
      }
    }

    .icon {
      width: 4.625rem;
      height: 4.625rem;
      display: block;
      align-self: flex-start;

      //same as button padding-left
      margin-right: 1em;
    }

    .cta-description{
      line-height: 1.3125rem;
    }
  }
}

@media screen and (min-width: 40em) {
  .cta-buttons .button {
    max-width: unset;
  }
}
