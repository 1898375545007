/* Helpers */
/* -------------------------------------------------------------------------------------------- */
/* Should be a separate file to put in utility css */

.split-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.show {
  display: block !important;
}

.hidden {
  display: none !important;
}

.danger {
  background: #c00;
  color: #fff;
}

ul.no-border li, ul.no-border {
  border: none !important;
}

.max-width\:measure {
  max-width: var(--measure);
}

.max-width\:measure\/2 {
  max-width: calc(var(--measure) / 2);
}

.display\:flex {
  display: flex !important;
}

.display\:flex-column {
  display: flex !important;
  flex-direction: column;
}

.flex\:stretch {
  align-items: stretch !important;
}

.flex\:grow {
  flex: 1 1 auto;
}

//-- Frankensteined Tailwind selectors --//

// Align self
.self\:center {
  align-self: center;
}

// Align items

.items\:stretch {
  align-items: stretch;
}

.items\:start {
  align-items: flex-start;
}

.items\:center {
  align-items: center;
}

.items\:end {
  align-items: flex-end;
}

.items\:baseline {
  align-items: baseline;
}

// Justify content

.justify\:start {
  justify-content: flex-start;
}

.justify\:center {
  justify-content: center;
}

.justify\:end {
  justify-content: flex-end;
}

.justify\:between {
  justify-content: space-between;
}

.justify\:around {
  justify-content: space-around;
}

// Border radius

.rounded\:full {
  border-radius: 9999px;
}

// Display

.block {
  display: block;
}

// Borders

.border {
  border: 1px solid #000;
}

.border\:l {
  border-left: 1px solid #000;
}

.border\:r {
  border-right: 1px solid #000;
}

.border\:b {
  border-bottom: 1px solid #000;
}

.border\:t {
  border-top: 1px solid #000;
}

.border-grey\:4 {
  border: 4px #e7e7e7 solid;
  border-radius: 4px;
}

//-- End tailwind --//

.debug {
  background: red !important;
}

/* Hide from browsers but make available to assistive technology */
/* https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.rounded {
  border-radius: 3px;
}

.underline {
  padding-bottom: 0.13em;
  box-shadow: inset 0 -0.13em white,
  inset 0 -0.35em $color-primary;
}

.text-wrap {
  min-width: 1%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

// ********** Padding **********

.padding\:sh {
  padding: $sh;
}

.padding\:s0 {
  padding: $s0;
}

// Padding top

.padding-top\:sh {
  padding-top: $sh;
}

.padding-top\:s0 {
  padding-top: $s0;
}

.padding-top\:s1 {
  padding-top: $s1;
}

.padding-top\:s2 {
  padding-top: $s2;
}

.padding-top\:s3 {
  padding-top: $s3;
}

.padding-top\:s4 {
  padding-top: $s4;
}

.padding-top\:s5 {
  padding-top: $s5;
}

.padding-top\:s6 {
  padding-top: $s6;
}

// Padding bottom

.padding-bottom\:sh {
  padding-bottom: $sh;
}

.padding-bottom\:s0 {
  padding-bottom: $s0;
}

.padding-bottom\:s1 {
  padding-bottom: $s1;
}

.padding-bottom\:s2 {
  padding-bottom: $s2;
}

.padding-bottom\:s3 {
  padding-bottom: $s3;
}

.padding-bottom\:s4 {
  padding-bottom: $s4;
}

.padding-bottom\:s5 {
  padding-bottom: $s5;
}

.padding-bottom\:s6 {
  padding-bottom: $s6;
}

// Padding right

.padding-right\:sh {
  padding-right: $sh;
}

.padding-right\:s0 {
  padding-right: $s0;
}

.padding-right\:s1 {
  padding-right: $s1;
}

.padding-right\:s2 {
  padding-right: $s2;
}

.padding-right\:s3 {
  padding-right: $s3;
}

.padding-right\:s4 {
  padding-right: $s4;
}

.padding-right\:s5 {
  padding-right: $s5;
}

.padding-right\:s6 {
  padding-right: $s6;
}

// Padding left

.padding-left\:sh {
  padding-left: $sh;
}

.padding-left\:s0 {
  padding-left: $s0;
}

.padding-left\:s1 {
  padding-left: $s1;
}

.padding-left\:s2 {
  padding-left: $s2;
}

.padding-left\:s3 {
  padding-left: $s3;
}

.padding-left\:s4 {
  padding-left: $s4;
}

.padding-left\:s5 {
  padding-left: $s5;
}

.padding-left\:s6 {
  padding-left: $s6;
}

// ********** Margin **********

// Margin top

.margin-top\:sh {
  margin-top: $sh;
}

.margin-top\:s0 {
  margin-top: $s0;
}

.margin-top\:s1 {
  margin-top: $s1;
}

.margin-top\:s2 {
  margin-top: $s2;
}

.margin-top\:s3 {
  margin-top: $s3 !important;
}

.margin-top\:s4 {
  margin-top: $s4;
}

.margin-top\:s5 {
  margin-top: $s5;
}

.margin-top\:s6 {
  margin-top: $s6;
}

.margin-top\:auto {
  margin-top: auto;
}

// Margin bottom

.margin-bottom\:sh {
  margin-bottom: $sh;
}

.margin-bottom\:s0 {
  margin-bottom: $s0;
}

.margin-bottom\:s1 {
  margin-bottom: $s1;
}

.margin-bottom\:s2 {
  margin-bottom: $s2;
}

.margin-bottom\:s3 {
  margin-bottom: $s3 !important;
}

.margin-bottom\:s4 {
  margin-bottom: $s4;
}

.margin-bottom\:s5 {
  margin-bottom: $s5;
}

.margin-bottom\:s6 {
  margin-bottom: $s6;
}

.margin-bottom\:auto {
  margin-bottom: auto;
}

// Margin right

.margin-right\:sh {
  margin-right: $sh;
}

.margin-right\:s0 {
  margin-right: $s0;
}

.margin-right\:s1 {
  margin-right: $s1;
}

.margin-right\:s2 {
  margin-right: $s2;
}

.margin-right\:s3 {
  margin-right: $s3;
}

.margin-right\:s4 {
  margin-right: $s4;
}

.margin-right\:s5 {
  margin-right: $s5;
}

.margin-right\:s6 {
  margin-right: $s6;
}

.margin-right\:auto {
  margin-right: auto;
}

// Margin left

.margin-left\:sh {
  margin-left: $sh;
}

.margin-left\:s0 {
  margin-left: $s0;
}

.margin-left\:s1 {
  margin-left: $s1;
}

.margin-left\:s2 {
  margin-left: $s2;
}

.margin-left\:s3 {
  margin-left: $s3;
}

.margin-left\:s4 {
  margin-left: $s4;
}

.margin-left\:s5 {
  margin-left: $s5;
}

.margin-left\:s6 {
  margin-left: $s6;
}

.margin-left\:auto {
  margin-left: auto;
}

// others

.pointer-events-none {
  pointer-events: none
}

.full-width {
  width: 100% !important;
}

.text-center {
  text-align: center !important;
}

.youtube-video {
  width: 100%;
  height: 577px;
}

.visibility\:hidden {
  visibility: hidden;
}

.no-border {
  border: none !important;
}

.button-with-icon {
  display: flex !important;
  align-items: center;
  padding: $sh $s0 !important;
}

.button-login {
  width: 260px !important;
  border: 1px solid #dbdbdb !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hint {
  color: $color-dark-grey;
}

.pre-wrap {
  white-space: pre-wrap;
}

.draggable {
  cursor: grab;
}

.draggable[data-dragging] {
  border: 2px dashed cyan !important;
}
