// Mixins
@import "utils/custom-outline";
@import "utils/media-query";
@import "utils/scaling-size";

// Definitions
$color-light-orange: #FEE0CF;
$color-light-blue: #D4EFFC;
$color-light-green: #EDF8EC;
$color-light-olive: #D5E2DB;
$color-light-red: #FDF3F3;
$color-light-yellow: #FCF4E2;

$color-adelaide-grey: #CFCFCF;
$color-coastal-blue: #96D0E9;
$color-barossa-sky-blue: #C7F9F4;
$color-yorkes-teal: #93D4D1;
$color-adelaide-hills-green: #73c98a;
$color-clare-pasture-green: #C7DE7F;
$color-glenelg-gold: #FCF059;

$site-width: 65rem;

// Breakpoints


// Base
@import "components/reset";
@import "components/base";
@import "components/environment";
@import "components/color";

// Dependencies
@import "~trix/dist/trix";
@import "~choices.js/src/styles/choices";
@import "~leaflet/dist/leaflet";
@import "~tiny-slider/src/tiny-slider";
@import "~flatpickr/dist/flatpickr";

// Components
@import "components/accordian";
@import "components/account_details";
@import "components/account_menu";
@import "components/actiontext";
@import "components/banner_components";
@import "components/campaign_page";
@import "components/carousel";
@import "components/carousel_projects";
@import "components/counter";
@import "components/connect";
@import "components/dev-banner";
@import "components/direct_uploads";
@import "components/feature_banner";
@import "components/flash";
@import "components/flex-col";
@import "components/footer";
@import "components/grant";
@import "components/hang";
@import "components/header";
@import "components/hit";
@import "components/inline_forms";
@import "components/layout";
@import "components/list";
@import "components/micromodal";
@import "components/modal";
@import "components/multi_select";
@import "components/navigation";
@import "components/options_dropdown";
@import "components/tool_items";
@import "components/pagination";
@import "components/person";
@import "components/print";
@import "components/primary_contact";
@import "components/progress_bar";
@import "components/rich_content";
@import "components/sbs_content_component";
@import "components/scrim";
@import "components/slide_menu";
@import "components/testimonial";
@import "components/utility";
@import "components/utility_bar";
@import "components/versions";
@import "components/webinar";
@import "components/youtube";
@import "components/client";
@import "components/banner_cta";
@import "components/search";

html {
  font-family: 'DM Sans', 'Noto Serif JP', 'Noto Sans KR', sans-serif;
}

*:focus {
  @include custom-outline;
}

/* Base layout */
/* -------------------------------------------------------------------------------------------- */

body {
  display: flex;
  flex-direction: column;
  // https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
  height: 100%;
  margin: 0;
}

main {
  flex: 1 0 auto;
  width: 100%;
}

header, footer {
  flex: none;
}

footer {
  background: #000;
  color: #FFF;
}


/* Layout overrides */
/* -------------------------------------------------------------------------------------------- */

.center--site-width {
  max-width: $site-width;
}

/* Max width excludes */
/* -------------------------------------------------------------------------------------------- */

.max-width-exclude {
  max-width: none;
}

/* Element */
/* -------------------------------------------------------------------------------------------- */

h1, .heading-one {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.3;
}

h2, .heading-two {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.3;
}

h3, .heading-three {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.3;
}

h4, .heading-four {
  font-size: 1rem;
  font-weight: bold;
}

.stack-element-margins h2, .stack-element-margins h3 {
  margin-top: 3rem !important;
}

.no-stack-element-margins h2, .no-stack-element-margins h3 {
  margin-top: $s1 !important;
}

a, .link {
  color: #000;
  text-decoration: underline;
}

a:hover, .link:hover {
  color: #808285;
  text-decoration: none;
}

.link--no-line {
  text-decoration: none;
}

.link-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  transform: translateY(25%);
}

.button, button, input[type=button], input[type=submit], input[type=reset] {
  display: inline-block;
  border: 2px solid #000;
  font-weight: bold;
  height: auto;
  width: max-content;
  padding: 0.25em 1em;
  color: #000;
  text-decoration: none;
  background-color: #FFF;

  svg {
    fill: #000;
    stroke: #000;
  }

  &:hover {
    background-color: #000;
    color: #fff;

    svg {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.button--reverse {
  border: 2px solid #FFF;
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #000;
  }
}

.button--border {
  border: 2px solid #FFF;
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #000;
  }
}

.button-reset {
  display: inline;
  border: 0;
  padding: 0;
  color: inherit;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    color: inherit;
  }
}

.button--disabled {
  background: #CFCFCF;
}

.trix-content {
  cta {
    display: block;
    > a[href] {
      @extend .button;
      border: none;

      @media screen and (max-width: $site-width) {
        width: auto; // force line breaks on mobile
      }
    }
  }

  // clipping issue when showing disc list style
  ul {
    padding-left: .1rem;

    ul {
      list-style: circle;
    }
  }

}

/* Card */
/* -------------------------------------------------------------------------------------------- */
// https://inclusive-components.design/cards/

.card {
  position: relative;
  background: #FFF;
  border-radius: 5px;
}

.card--blue-background{
  background: $color-coastal-blue
}

.card--with-spacing {
  padding: $s1;
}

.card, .card .card__body {
  display: flex;
  flex-direction: column;
}

.card .card__image img {
  border-radius: 0 0 0 0;
}

.card .card__body {
  flex-grow: 1;
  max-width: 60ch;
}

.card .card__footer {
  padding-top: 1rem;
  margin-top: auto;
}

ul.cards {
  padding-left: 0;
  list-style: none;
  max-width: none;
}


// Card links

.card a {
  text-decoration: none;
}

.card:not(.card--no-link) a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.card a:focus {
  @include custom-outline;
  text-decoration: underline;
}

.card:focus-within, .card:hover {
  // box-shadow: 0 0 0 0.25rem var(--color-primary);
}

.card:focus-within a:focus {
  text-decoration: none;
}

// Card flex grid
// Sass min doesn't like mixing units
// https://stackoverflow.com/questions/54090345/sass-incompatible-units-vw-and-px

.grid-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.grid-items_gutter-medium {
  margin-left: -1.1rem;
  margin-right: -1.1rem;
}

.grid-items_gutter-medium .grid-item {
  margin: 1.1rem;
}

.grid-item {
  flex: 1 1 100%;
  margin: 0.5rem;
}

.grid-item__no-margin {
  margin: 0;
}

@media screen and (min-width: 40em) {
  .grid-item {
    flex-basis: 500px;
    max-width: calc(50% - 1em);
    // background:green;
  }
}

@media screen and (min-width: $site-width) {
  .grid-items--two-up .grid-item {
    flex-basis: 500px;
    max-width: calc(50% - 1rem);
  }

  .grid-items--two-up > .grid-item-60 {
    flex-basis: 600px;
    max-width: calc(60% - 1rem);
  }

  .grid-items--two-up > .grid-item-40 {
    flex-basis: 400px;
    max-width: calc(40% - 1rem);
  }

  .grid-items_gutter-medium.grid-items--three-up > .grid-item {
    max-width: calc(33.33% - 2.2rem)
  }

  .grid-items--three-up > .grid-item {
    flex-basis: 500px;
    max-width: calc(33.33% - 1rem)
  }

  .grid-items--four-up > .grid-item {
    flex-basis: 500px;
    max-width: calc(25% - 1em)
  }

  .grid-items--five-up > .grid-item {
    flex-basis: 500px;
    max-width: calc(20% - 1em)
  }
}


/* Box overrides */
/* -------------------------------------------------------------------------------------------- */

.box {
  border: none;
  border-radius: $border-radius;
}

.box-no-pad {
  padding: 0;
}

.box-border {
  padding: 0;
  border: 1px solid var(--color-mid-grey);
}

.box-primary {
  background-color: $color-light-orange;
}

.box-secondary {
  background-color: var(--color-secondary);
}

.box-light-orange {
  background-color: $color-light-orange;
}

.box-light-blue {
  background-color: $color-light-blue;
}

.box-light-grey {
  background-color: #eee;

  input, textarea, select {
    border: 1px solid black;
    background: #fff;
  }

  select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
  }

}


/* Banner */
/* -------------------------------------------------------------------------------------------- */

.banner--image {
  width: 100%;
  max-width: none;
}

.banner-full {
  width: 100%;
  height: 10rem;
  max-width: none;
}

.banner-full > img {
  width: 100%;
  max-width: none;
  height: 100%;
  object-fit: cover;
}

.banner-bg-grey {
  background: #EEEFEF;
  flex: none;
}

.banner-bg-grey > .center {
  max-width: 100ch;
}

ul.fixed {
  white-space: nowrap;
}

/* Bacgkground cover image */
/* -------------------------------------------------------------------------------------------- */

.bg-cover-img {
  background-size: cover;
  background-position: center;
}

.bg-cover-img {
  display: block;
  position: relative;

  .watermark {
    position: absolute;
    right: 0;
  }

  @media screen and (max-width: 40em) {
    .watermark {
      display: none;
    }
  }
}

.bg-cover-img .centered > h2 {
  padding-top: 7rem;
  color: #FFF;
}

.bg-cover-img h2.alone {
  padding-top: 7rem;
  padding-left: 2rem;
  height: 30rem;
}

.bg-cover-img .centered h2.alone {
  padding-top: 7rem;
  padding-left: 0;
}

@media screen and (max-width: $site-width) {
  .bg-cover-img .centered h2.alone {
    padding-left: 1rem;
  }
}

.bg-cover-img .fix-height {
  height: 30rem;
}

/* Project list view */
/* -------------------------------------------------------------------------------------------- */

li.project-list {
  flex: none;
  display: block;
  width: 100%;
  margin: $sh 0;
  background: #FFF;
  max-width: none;
  border-radius: 4px;
}

/* Misc */
/* -------------------------------------------------------------------------------------------- */

// Force height based on number of lines

@mixin webkit-line-clam($lines) {
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lines--two {
  max-height: 2.6em;
  line-height: 1.3;
  @include webkit-line-clam(2);
}

.lines--three {
  max-height: 3.9em;
  line-height: 1.3;
  @include webkit-line-clam(3);
}

.lines--four {
  max-height: 6em;
  line-height: 1.5;
  @include webkit-line-clam(4);
}

.lines--six {
  max-height: 9em;
  line-height: 1.5;
  @include webkit-line-clam(6);
}

.centered {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: $site-width;
}

@media screen and (max-width: $site-width) {
  .centered {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.full-height {
  height: 100%;
}

.vertical-center {
  margin: auto 0;
}

.page-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.page-padding--page {
  padding-top: $s4;
  padding-bottom: $s4;
}

.page-padding--page-heading {
  padding-top: $s4;
  padding-bottom: $s1;
}

.no-wrap {
  white-space: nowrap;
}

.field-errors {
  padding-left: 0;
  list-style-type: none;
  max-width: none;
}

// Backgrounds

.background\:light-orange {
  background-color: $color-light-orange;
}

.background\:light-blue {
  background-color: $color-light-blue;
}

.background\:light-green {
  background-color: $color-light-green;
}

.background\:light-olive {
  background-color: $color-light-olive;
}

.background\:light-red {
  background-color: $color-light-red;
}

.background\:light-yellow {
  background-color: $color-light-yellow;
}

.background\:light-grey {
  background-color: $color-light-grey;
}

.background\:adelaide-grey-100 {
  background-color: $color-adelaide-grey;
}

.background\:adelaide-grey-75 {
  background-color: rgba($color-adelaide-grey, 0.75);
}

.background\:adelaide-grey-50 {
  background-color: rgba($color-adelaide-grey, 0.5);
}

.background\:adelaide-grey-25 {
  background-color: rgba($color-adelaide-grey, 0.25);
}

.background\:coastal-blue-100 {
  background-color: $color-coastal-blue;
}

.background\:coastal-blue-75 {
  background-color: rgba($color-coastal-blue, 0.75);
}

.background\:coastal-blue-50 {
  background-color: rgba($color-coastal-blue, 0.50);
}

.background\:coastal-blue-25 {
  background-color: rgba($color-coastal-blue, 0.25);
}

.background\:barossa-sky-blue-100 {
  background-color: $color-barossa-sky-blue;
}

.background\:barossa-sky-blue-75 {
  background-color: rgba($color-barossa-sky-blue, 0.75);
}

.background\:barossa-sky-blue-50 {
  background-color: rgba($color-barossa-sky-blue, 0.50);
}

.background\:barossa-sky-blue-25 {
  background-color: rgba($color-barossa-sky-blue, 0.25);
}

.background\:yorkes-teal-100 {
  background-color: $color-yorkes-teal;
}

.background\:yorkes-teal-75 {
  background-color: rgba($color-yorkes-teal, 0.75);
}

.background\:yorkes-teal-50 {
  background-color: rgba($color-yorkes-teal, 0.5);
}

.background\:yorkes-teal-25 {
  background-color: rgba($color-yorkes-teal, 0.25);
}

.background\:adelaide-hills-green-100 {
  background-color: $color-adelaide-hills-green;
}

.background\:adelaide-hills-green-75 {
  background-color: rgba($color-adelaide-hills-green, 0.75);
}

.background\:adelaide-hills-green-50 {
  background-color: rgba($color-adelaide-hills-green, 0.5);
}

.background\:adelaide-hills-green-25 {
  background-color: rgba($color-adelaide-hills-green, 0.25);
}

.background\:clare-pasture-green-100 {
  background-color: $color-clare-pasture-green;
}

.background\:clare-pasture-green-75 {
  background-color: rgba($color-clare-pasture-green, 0.75);
}

.background\:clare-pasture-green-50 {
  background-color: rgba($color-clare-pasture-green, 0.5);
}

.background\:clare-pasture-green-25 {
  background-color: rgba($color-clare-pasture-green, 0.25);
}

.background\:glenelg-gold-100 {
  background-color: $color-glenelg-gold;
}

.background\:glenelg-gold-75 {
  background-color: rgba($color-glenelg-gold, 0.75);
}

.background\:glenelg-gold-50 {
  background-color: rgba($color-glenelg-gold, 0.5);
}

.background\:glenelg-gold-25 {
  background-color: rgba($color-glenelg-gold, 0.25);
}

.background\:transparent {
  background-color: transparent;
}

/* Custom Inputs */
/* -------------------------------------------------------------------------------------------- */

$control-size: 1.5rem;
$line-size: 1.5rem;
$focus-color: rgba(0, 0, 0, .33);
$focus-width: 4px;

select {
  background-image: url('../images/select-arrow.svg'), linear-gradient(to bottom, $field-background-color 0%, $field-background-color 100%);
}

.radio, .checkbox {
  position: relative;

  input {
    position: fixed;
    opacity: 0;
  }

  label {
    font-weight: normal;
    padding: calc(($control-size - $line-size) / 2) 0;
    padding-left: $control-size + .5rem;
    margin-bottom: 0;
    display: inline-block;

    &::before {
      content: "";
      box-sizing: border-box;
      display: block;
      width: $control-size;
      height: $control-size;
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      border: 1px solid #000;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.checkbox {
  input:checked + label {
    &::before {
      background-image: url('../images/checkbox-tick.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
      background-color: $color-primary;
    }
  }

  input:focus + label {
    &::before {
      @include custom-outline;
    }
  }
}

.radio {
  label {
    &::before {
      border-radius: 50%;
      box-shadow: inset 0 0 0 .35rem #fff;
    }
  }

  input:checked + label {
    &::before {
      background: #000;
      box-shadow: inset 0 0 0 .35rem $color-primary;
    }
  }

  input:focus + label {
    &::before {
      box-shadow: 0 0 0 $focus-width $focus-color, inset 0 0 0 .35rem #fff;
    }
  }

  input:focus:checked + label {
    &::before {
      box-shadow: 0 0 0 $focus-width $focus-color, inset 0 0 0 .35rem $color-primary;
    }
  }
}

.collection-checkbox, .collection-radio, .boolean-radio {
  margin: $sh 0;
}


/* Actions */
/* -------------------------------------------------------------------------------------------- */

.actions {
  display: flex;

  & > * + * {
    display: block;
    margin-left: .5rem;
  }
}

#skip-link a {
  position: absolute;
  top: -4rem;
  left: 1rem;
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 100;

  &:focus {
    position: absolute;
    top: 1rem;
    outline-color: rgba(0, 0, 0, 0.33);
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
  }
}
