// Hides navigation and footer on campaign pages
body.campaign-page {
  header[role=banner] {
    display: none;
  }

  footer {
    display: none;
  }
}
