@import "../utils/layout";

/* Stack */
/* -------------------------------------------------------------------------------------------- */

.stack {
  @include stack;
}

.stack--recursive {
  @include stack--recursive;
}

.stack-sh {
  @include stack-element {
    margin-top: $sh;
  }
}

.stack-s0 {
  @include stack-element {
    margin-top: $s0;
  }
}

.stack-s1 {
  @include stack-element {
    margin-top: $s1;
  }
}

/* Box */
/* -------------------------------------------------------------------------------------------- */

.box {
  @include box;
}

/* Center */
/* -------------------------------------------------------------------------------------------- */

.center {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}



/* Cluster */
/* -------------------------------------------------------------------------------------------- */

.cluster {
  overflow: hidden;
}

.cluster > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: calc(#{$s1} / 2 * -1);
}

.cluster > * > * {
  margin: calc(#{$s1} / 2);
}

.cluster_space-between > * {
  justify-content: space-between;
}

.cluster_s0 > * {
  margin: calc(#{$s0} / 2 * -1);
}

.cluster_s0 > * > * {
  margin: calc(#{$s0} / 2);
}

.cluster_no-margin > * {
  margin: 0
}

.cluster_no-margin > * > * {
  margin: 0
}


/* Sidebar */
/* -------------------------------------------------------------------------------------------- */

.with-sidebar {
  overflow: hidden;
}

.with-sidebar > * {
  display: flex;
  flex-wrap: wrap;
  margin: calc(#{$s1} / 2 * -1);
  align-items: flex-stretch;
}

.with-sidebar > * > * {
  margin: calc(#{$s1} / 2);
  flex-basis: 20rem;
  flex-grow: 1;
}

.with-sidebar-right > * > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(50% - #{$s1});
}

.with-sidebar-left > * > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(50% - #{$s1});
}

/* Grid */
/* -------------------------------------------------------------------------------------------- */

.grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Frame */
/* -------------------------------------------------------------------------------------------- */


.frame {
  $n: 9;
  $d: 16;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.frame\:324x76 {
  $n: 76;
  $d: 324;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.frame\:1280x220 {
  $n: 220;
  $d: 1280;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.frame\:244x177 {
  $n: 177;
  $d: 244;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.frame\:448x571 {
  $n: 200;
  $d: 300;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.frame > * {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame > img,
.frame > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Icon */
/* -------------------------------------------------------------------------------------------- */

.icon {
  width: 1.5em;
  height: 1.5em;
  display: block;
}

.icon-small {
  width: 1em;
  height: 1em;
}

.icon--hit {
  padding: .5rem;
  box-sizing: content-box;
}

.icon--large {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
}

.icon--large-wide {
  width: 3rem;
  height: 2.25rem;
  display: block;
}

.with-icon {
  display: inline-flex;

  .icon {
    margin-right: 0.7rem;
    flex-shrink: 0;
  }
}

.with-icon--reversed {
  display: flex;
  justify-content: space-between;

  .icon {
    order: 1;
    margin-left: 0.7rem;
    flex-shrink: 0;
  }
}

.icon--file-type {
  display: inline-flex;

  &::before {
    content: "";
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.7rem;
    flex-shrink: 0;
    background-image: url('../images/file-type-icons/file.svg');
  }

  &[href$=".pdf"] {
    &::before {
      background-image: url('../images/file-type-icons/pdf.svg');
    }
  }

  &[href$=".ppt"],
  &[href$=".pptx"],
  &[href$=".pptm"] {
    &::before {
      background-image: url('../images/file-type-icons/ppt.svg');
    }
  }

  &[href$=".doc"],
  &[href$=".docx"],
  &[href$=".docm"] {
    &::before {
      background-image: url('../images/file-type-icons/doc.svg');
    }
  }
}
