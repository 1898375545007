$progress-bar-height: .5rem;

@mixin progress-bar-step {
  content: "";
  position: absolute;
  width: $progress-bar-height;
  height: $progress-bar-height;
  right: calc($progress-bar-height / -2);
  top: 0;
  display: block;
  background: #000;
  border-radius: calc($progress-bar-height / 2);
  z-index: 1;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.progress-bar__heading {
  margin-right: 1rem;
  font-weight: bold;
  flex-shrink: 0;
}

.progress-bar__list {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 32rem !important;
  justify-content: space-between;
  position: relative;
  margin-right: calc($progress-bar-height / 2);
}

.progress-bar__item {
  flex: 1 1 100%;
  height: $progress-bar-height;
  background: #000;
  text-indent: -9999999px;
  background: #fff;
  position: relative;

  &::after {
    @include progress-bar-step;
  }

  &:first-child {
    border-radius: calc($progress-bar-height / 2) 0 0 calc($progress-bar-height / 2);
  }
}

.progress-bar__item--completed {
  background: $color-primary;
}
