
nav[role=account-menu] {
  min-width: 12rem;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  position: absolute;
  top: 75%;
  right: 1rem;
  display: none;
  z-index: 100;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    top: -10px;
    right: 1.2rem;
    box-sizing: border-box;
    border: 10px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  }

  &[data-active] {
    display: block;
  }

  [role=menuitem] {
    text-decoration: none;
    display: block;
    padding: 0 1.5rem .75rem 1.5rem;

    &:focus {
      outline: none;
    }
  }

  [role=menu] {
    padding-top: 1rem;
    background: white;
  }

  .logout {
    font-weight: bold;
    border-top: 2px solid $color-light-grey;
    padding-top: .75rem;
  }

}
