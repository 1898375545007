.flash__wrapper {
  position: fixed;
  display: block;
  max-width: 100%;
  width: 420px;
  margin: 0 !important;
  padding: $s0 0;
  top: 0;
  right: 0;
  z-index: 100;

  > * {
    padding: 0 $s0;
  }
}

[data-controller="flash"] {
  position: relative;
}

[data-controller="flash"][data-hide] {
  opacity: 0;
}

.flash {
  position: relative;
  padding: $s0;
  border-radius: $border-radius;
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 0.5rem;

  > .icon {
    margin-right: 0.7rem;
  }
}

.flash--message {
  flex-grow: 1;
}

.flash--close {
  margin-left: 0.7rem;
  cursor: pointer;
  align-self: flex-start;

  .icon {
    width: $sh;
    height: $sh;
    margin: 0;
  }
}

.flash.alert {
  background-color: $color-light-yellow;
  border-color: #faeeca;
}

.flash.success {
  background-color: $color-light-green;
  border-color: #d4eed1;
}

.flash.notice {
  background-color: $color-light-blue;
  border-color: #95dcff;
}

.flash.error {
  color: black !important;
  background-color: $color-light-red;
  border-color: #f4cfce;
}
