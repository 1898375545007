$banner-breakpoint: 764px;

// ==============================================================================
// LAYOUTS
// ==============================================================================

.section-padding {
  padding: $s2 0;
}

#main-content > .page-heading + section {
  padding-top:0;
}

// ==============================================================================
// COMPONENTS
// ==============================================================================

.rich-content-banner {
  width: 100%;
  min-height: 50vh;
  max-width: none;
  display: flex;
  align-items: center;
  padding: 3rem 6rem;
  position: relative;
  text-decoration: none;

  @include media-query($banner-breakpoint) {
    padding: 0;
    display: block;
    min-height: 0;
  }

  // IE11 min-height flex bug https://github.com/philipwalton/flexbugs/issues/231
  &::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

.rich-content-banner--image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;

  @include media-query($banner-breakpoint) {
    width: 100%;
    padding-bottom: 66.66%;
    position: static;
  }
}

.rich-content-banner--content {
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  width: 40rem;
  z-index: 2;
  padding: 1.375rem;

  @include media-query($banner-breakpoint) {
    width: 100%;
    border-radius: 0;
    margin-left: 0;
  }
}

.light {
  color: #FFFFFF !important;
}

.dark {
  color: #000000 !important;
}

.grid-item {
  .section-padding {
    padding: 0;
  }

  .centered {
    margin: 0;
  }
}

.modal {
  .section-padding {
    padding: 0;
  }

  .centered {
    margin: 0;
  }
}
