.form-inline {
  display: flex;

  input:not(:last-child),
  select:not(:last-child),
  button:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0;
  }

  input:not(:first-child),
  select:not(:first-child) {
    border-left-width: 0;
  }

  input:not(:first-child),
  select:not(:first-child),
  button:not(:first-child),
  input + button,
  select + button,
  input + a,
  select + a {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input + input:not(:first-child),
  input + select:not(:first-child),
  select + input:not(:first-child),
  select + select:not(:first-child) {
    border-left-width: 1px;
  }

  input,
  select {
    flex-grow: 1;
    flex-shrink: 1;
  }

  button {
    flex-grow: 0;
    flex-shrink: 0;
    border-width: 1px;
  }
}

.form-inline--cap {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  background: #fff;
  border: 1px solid #000;
  flex-shrink: 0;
  flex-grow: 0;

  &:first-child {
    border-top-left-radius: 0px; //3px
    border-bottom-left-radius: 0px; //3px
  }

  &:last-child {
    border-top-right-radius: 0px; //3px
    border-bottom-right-radius: 0px; //3px
  }

  & + .form-inline--cap {
    border-left: 0;
  }
}
