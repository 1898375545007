$gutter: $s1;

/**
  @flex-row
  Creates a wrapper for your columns with a custom gutter:
  @include flex-row(10px);
*/
@mixin flex-row($gutter: false) {
  display: flex;
  flex-wrap: wrap;
  @if $gutter {
    @if type-of($gutter) == "list" {
      margin-top: nth($gutter, 1) * -.5;
      margin-bottom: nth($gutter, 1) * -.5;
      margin-left: nth($gutter, 2) * -.5;
      margin-right: nth($gutter, 2) * -.5;
    } @else {
      margin: $gutter * -.5;
    }
  }
}

/**
  @flex-col
  Crates a column with a specified gutter and width
  1.  To create a column 50% wide with a 10px gutter:
      @include flex-col(50%, 10px);
  2.  To create a column 50% wide that responds to 100% wide:
      @include flex-col(50%, 10px);
      @include media-query(800px) {
        @include flex-col(50%);
      }
*/
@mixin flex-col($width, $gutter: false, $grow: 0, $shrink: 0) {
  @if $gutter {
    @if type-of($gutter) == "list" {
      padding-top: nth($gutter, 1) * .5;
      padding-bottom: nth($gutter, 1) * .5;
      padding-left: nth($gutter, 2) * .5;
      padding-right: nth($gutter, 2) * .5;
    } @else {
      padding: $gutter * .5;
    }
  }
  flex-grow: $grow;
  flex-shrink: $shrink;
  width: $width;
}
