.news-filters {
  display: flex;
  justify-content: space-between;

  .choices {
    width: 32%;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .multi-select {
    flex-grow: 1;
    flex-basis: 0;

    border-radius: 4px;

    &:not(:last-child) {
      margin-right: 2%;
    }
  }
}

.news-filters-secondary {
  padding: 1rem 0 0.70rem 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $site-width) {
    padding: 1rem 1rem 0.7rem 1rem;
  }
}

.news-view-summary {
  margin: auto 0;

  span:not(:last-child) {
    margin-right: 16px;
  }
}

.news-view-options {
  display: flex;
  border: 1px solid #000;
  border-radius: 2px;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    background-color: #fff;
    padding: 10px 20px 2px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
  }

  label:hover {
    background-color: $color-primary;
  }

  input[type="radio"]:checked + label {
    background-color: $color-primary;
  }

  .radio {
    &:not(:last-child) {
      border-right: 1px solid #000;
    }

    label::before {
      visibility: hidden;
    }
  }
}

// Grid view

.news-insights .frame {
  $n: 9;
  $d: 12.5;
  padding-bottom: calc(#{$n} / #{$d} * 100%);
  position: relative;
}

.news-item--article-type {
  background: #ffedaf;
  width: fit-content;
  padding: 4px 8px;
}

.news-item--breaker {
  border-top: 1px dashed lightgray;
}

.news-item__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.news-item-caption,
.news-item-labels {
  color: dimgray;
}

// List view

li.news-item-list {
  flex: none;
  display: block;
  width: 100%;
  border: 1px solid #D4D4D4;
  background: #FFF;
  max-width: none;
  border-radius: 4px;
  padding: 1rem 1.5rem;
}

.frame-header-wrapper {
  display: block;
  height: 76px;
  background-size: cover;
  background-position-y: center;
}

.news-content-container {
  flex-wrap: wrap;

  @media screen and (max-width: $site-width) {
    flex-wrap: nowrap;
  }

  .section-padding:first-of-type {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: $site-width) {
  .news-view-options {
    .with-icon {
      .icon {
        margin-right: 0;
      }

      .text {
        display: none;
      }
    }
  }
}
