$category-nav-breakpoint: 640px;

.utility-bar {
  border-bottom: 1px solid #000;
}

.utility-bar__bar {
  padding: .75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.utility-bar__filters {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > * + * {
    margin-left: 1rem;
  }
}

.utility-bar__search {
  max-width: 320px;
  width: 100%;
  margin-left: 2rem;
}

.utility-bar__view-options {
  padding: 1rem 0;
  display: flex;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: .75rem;
    }
  }
}

.utility-bar__dropdown-clear-filters {
  font-weight: bold;
  margin-top: 1rem;
  display: block;
}

.utility-bar__dropdown {
  display: none;
  padding: 1rem 0;
  border-bottom: 1px solid #000;
}

.utility-bar--mobile-hidden {
  @include media-query(600px) {
    display: none;
  }
}
