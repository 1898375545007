// ########################################################
// ALL BANNER COMPONENTS
// ########################################################

.banner--description {
  font-size: 1.125em;
}

// ########################################################
// PAGE HEADER COMPONENT
// ########################################################

.page-header-component--wrapper {
  min-height: 170px;
}

.page-header-component {
  display: flex;
  justify-content: space-between;
}

.page-header-component--text--wrapper {
  display: flex;
  align-items: center;
  min-height: 170px;
}

.page-header-component--text {
  margin: 32px 20px 32px 0;
  max-width: 60ch;
}

.page-header-component--image {
  margin: 12px 0;
  height: 194px;

  img {
    min-width: 260px;
    height: 194px;
  }
}

@media screen and (max-width: 40em) {
  .page-header-component {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .page-header-component--text {
    margin-bottom: 32px;
  }

  .page-header-component--image {
    height: 100px;
    margin: 32px 0 0 0;
  }

  .page-header-component--image img {
    min-width: unset;
    width: 130px;
    height: 100px;
  }
}

// ########################################################
// FEATURE BANNER COMPONENT
// ########################################################

.feature-banner-component {
  max-height: 480px;

  > .bg-cover-img {
    z-index: -1;
  }
}

.feature-banner-component--wrapper {
  display: flex;
  width: 50%;
  height: 30rem;
  margin-left: auto;
}

.feature-banner-component--text {
  margin: auto 10rem auto 2rem;
}

.feature-banner-component--only-title {
  display: flex;
  height: 30rem;

  > * {
    margin: auto;
    width: 15ch;
    text-align: center;
  }
}

@media screen and (max-width: 1040px) {
  .feature-banner-component--wrapper {
    width: 100%;
  }

  .feature-banner-component--text {
    margin: auto 0 0 0;
    padding: 1rem scaling-size(2, 10, rem) 2rem 1rem;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.feature-banner-component--video-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  position: absolute;

  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 40em) {
    display: none;
  }
}

// ########################################################
// BANNER COMPONENT (BG IMAGE)
// ########################################################

.banner-component--wrapper .centered {
  display: flex;
  min-height: 170px !important;
}

.banner-component--wrapper > .bg-cover-img {
  min-height: 170px;

  .centered > h2 {
    padding-top: 0 !important;
  }
}

.banner-component {
  display: flex;
  margin: 32px 0;
}

.banner-component--text--wrapper {
  margin: auto 0;
}

.banner-component--text {
  max-width: 60ch;
}
