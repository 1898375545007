.client-avatar-wrapper {
  overflow: hidden;

  & > div {
    transition: transform 0.4s;
  }

  &:hover > div {
    transform: scale(1.2, 1.2);
  }
}

.carousel-modal__container {
  position: relative;

  .modal__close {
    z-index: 100;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;

    &:hover {
      background-color: #000000;
    }

    &:before {
      content: "\2715";
    }
  }

  @media only screen and (max-width: 764px) {
    .carousel .tns-controls {
      position: absolute;
      top: 15%;
    }
  }

  padding: 0;

  //width from _micromodal.scss is too small for our clients carousel
  max-width: $site-width;
  width: $site-width;

  .carousel {
    background-color: white;
  }

  .client-image {
    width: 100%
  }

  .client-info {
    width: 100%;
  }

  .client-wrapper {
    display: flex;
  }

  @include media-query($carousel-breakpoint, min) {
    max-height: 571px;
    height: 80%;

    .carousel-slide {
      padding: 0;
      height: 571px;
      min-height: initial;
    }

    .client-wrapper {
      height: 571px;

      .client-image {
        flex: 0 0 45%;
        max-width: 45%;
        height: 100%;
      }

      .client-info {
        flex: 0 0 55%;
        max-width: 55%;
        height: 100%;

        display: flex;
        flex-direction: column;
        //justify-content: center;

        & > div {
          overflow-y: auto;
          padding: 8%;
          padding-right: 16%;
          margin-top: auto;
          margin-bottom: auto;
        }

      }
    }

  }

  @include media-query($carousel-breakpoint) {
    .client-wrapper {

      flex-direction: column;

      .client-image {
        width: 100%;
        padding-bottom: 100%;
        position: relative;

        & > div {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      .client-info {
        width: 100%;
        padding: 2%;
      }
    }

  }

}

.client-thumbnails {
  .client-thumbnail-title {
    text-align: center;
  }

  .tns-outer {
    position: relative;
  }

  .tns-controls {
    %control-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: unset;
      border: unset;
      box-sizing: content-box; // Safari & iOS bugfix
    }

    [data-controls='prev'] {
      @extend %control-buttons;
      left: 0;
    }

    [data-controls='next'] {
      @extend %control-buttons;
      right: 0;
    }
  }
}
