.person {
  @include flex-row($gutter);
  flex-wrap: unset;

  @media screen and (max-width: 45rem) {
    flex-wrap: wrap;
  }
}

.person__image {
  @include flex-col(33.33%, $gutter);
  max-width: 12.5rem;
}

.person__image__sidebar {
  @include flex-col(66.66%, $gutter);
  margin: auto;
}

.person__content {
  @include flex-col(66.66%, $gutter);
  width: unset;
  flex-grow: 1;
  flex-shrink: unset;
}

.person__content__sidebar {
  @include flex-col(100%, $gutter);
}

.person--force-wrap {
  flex-wrap: wrap !important;
}

.people-icon--spacing {
  margin-left: 1rem;

  @media screen and (max-width: $site-width) {
    margin-left: 0;
  }
}
