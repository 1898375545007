.avatar-wrapper {
  float: left;
  margin-right: 8px;

  img {
    border-radius: 28px;
  }

  svg, img {
    height: 40px;
    width: 40px;
  }
}

.webinar-view-button {
  display: flex !important;
  align-items: center;
  background-color: $color-primary !important;
}

.event-info-wrapper {
  margin-left: 5rem;
}

@media screen and (max-width: 40em) {
  .event-info-wrapper {
    margin-left: 0;
  }
}

