$color-primary-connectplus: #73C98A;
$color-primary-invest: #C7F9F4;
$color-primary-export: #96D0E9;
$color-primary-dti: #000000;
$site-name-radius: 20px;

.search-icon {
  cursor: pointer;
}

.search-form {
  max-width: 100%;

  .search-field {
    position: relative;
    width: 100%;
    display: -ms-grid;
    display: grid;
    justify-items: end;

    input {
      grid-column: 1/2;
      grid-row: 1/2;
      margin: 0; // Safari appears to add a 2px top/bottom margin
      max-width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      transition: opacity 0.25s;
      grid-column: 1/2;
      grid-row: 1/2;
      border: none;
      background: transparent;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.search-toolbar {
  background: #fff;
  position: absolute;
  top: calc(100% + 3px); // extra 3px for the border on the header toolbar
  left: 0;
  right: 0;
  z-index: 10;

  &::after {
    content: '';
    display: block;
    height: 8rem;
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;

    .search-form {
      flex-grow: 1;
    }
  }
}

ul.search-result {
  border: 1px solid #D4D4D4;
  border-radius: $border-radius;
  background-color: white;

  li {
    max-width: 100%;

    &.item-on-invest {
      border-left: 10px solid $color-primary-invest;
    }

    &.item-on-export {
      border-left: 10px solid $color-primary-export;
    }

    &.item-on-dti {
      border-left: 10px solid $color-primary-dti;
    }

    &.item-on-connectplus {
      border-left: 10px solid $color-primary-connectplus;
    }

  }

  li + li {
    border-top: 1px solid #D4D4D4;
  }

  .site-invest {
    padding: 0 5px;
    border-radius: $site-name-radius;
    background-color: $color-primary-invest;
  }

  .site-export {
    padding: 0 5px;
    border-radius: $site-name-radius;
    background-color: $color-primary-export;
  }

  .site-dti {
    padding: 0 5px;
    border-radius: $site-name-radius;
    background-color: $color-primary-dti;
    color: white;
  }

  .site-connectplus {
    padding: 0 5px;
    border-radius: $site-name-radius;
    background-color: $color-primary-connectplus;
  }

  b {
    background-color: #ffedaf;
  }

}

[data-target='search.content'] {
  overflow: hidden;
}

[data-target='search.toolbar'][active] {
  z-index: 11;
}
