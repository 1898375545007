%environment {
  background: #000;
  color: #fff;
  display: block;
  padding: 0.8em 0;
  text-align: center;
}

.environment-development:before {
  @extend %environment;
  background: #666;
  content: "DEVELOPMENT";
}

.environment-uat:before {
  @extend %environment;
  background: #900;
  content: "UAT";
}
