.scrim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11;
}

.scrim[hidden] {
  display: none;
}
