@import "../utils/custom-outline";
@import "../utils/media-query";

$footer-breakpoint: 40em;

footer .heading-four {
  font-size: 1.125em;
}

////////////////////////////////////////////
// DESKTOP
////////////////////////////////////////////

.footer--desktop {
  @include media-query($footer-breakpoint) {
    display: none;
  }
}

.footer *:focus {
  @include custom-outline(rgba(255, 255, 255, 0.33));
}

.footer__bottom {
  @include media-query($footer-breakpoint, min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.footer__dti-logo {
  display: block;
  width: 215px;
  height: 90px;
}

.footer--breaker {
  border-top: 1px solid #1b1b1b;
}

////////////////////////////////////////////
// MOBILE
////////////////////////////////////////////

.footer--mobile {
  @include media-query($footer-breakpoint, min) {
    display: none;
  }
}

.footer--mobile a {
  color: #fff;
  padding: 0 16px;
}

.footer--mobile--item {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
}

.footer--mobile .logo-link {
  display: flex;
  padding: 0;
}

.footer--mobile .social {
  padding: 0 16px;
}

.footer--mobile .cluster > * {
  margin: calc(1.375rem / 2 * -3) calc(1.375rem / 2 * -1);
}
