.youtube-modal-content {
  background: rgba(0, 0, 0, 1);
  max-width: unset;
  max-height: unset;
  width: 50vw;
  padding: 0;
  position: relative;
  overflow-y: unset;

  @media screen and (max-width: 40rem){
    width: 95vw;
  }
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
  padding: 0.5rem 0.9rem;
  border-radius: 50%;
  z-index: 100;
}
