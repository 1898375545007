$carousel-breakpoint: 764px;

.carousel {
  position: relative;
  background: $color-primary;

  *:focus {
    @include custom-outline;
    border: 0;
    -moz-outline-style: none;
  }

  .tns-controls {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    box-shadow: none; // Hide outline as stretches whole box

    button {
      border-radius: 50%;
      color: #fff;
      margin: 0 0.5em;
      pointer-events: auto;
      background: transparent;
      border: 0;
    }

    @include media-query($carousel-breakpoint) {
      position: relative;
      transform: translate(0);
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: .75rem;
    display: flex;
    justify-content: center;
    z-index: 2;

    button {
      width: .75rem;
      height: .75rem;
      padding: 0;
      border-radius: 50%;

      & + button {
        margin-left: .25rem;
      }

      &.tns-nav-active {
        background: #000;
      }
    }
  }

}

.carousel-slide {
  width: 100%;
  min-height: 50vh;
  max-width: none;
  display: flex;
  align-items: center;
  padding: 3rem 6rem;
  position: relative;
  text-decoration: none;

  &:hover {
    color: #000;
  }

  &:hover .button--reverse {
    border: 2px solid #000;
    background-color: #fff;
    color: #000;
  }

  @include media-query($carousel-breakpoint) {
    padding: 0;
    display: block;
    min-height: 0;
  }

  p {
    text-decoration: none;
  }

  // IE11 min-height flex bug https://github.com/philipwalton/flexbugs/issues/231
  &::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

.carousel-content {
  background: #fff;
  width: 30rem;
  z-index: 2;
  //margin-left: auto;

  @include media-query($carousel-breakpoint) {
    width: 100%;
    border-radius: 0;
    margin-left: 0;
  }
}

.carousel-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;

  .watermark {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100px;
    margin-right: 15rem;
  }

  @include media-query($carousel-breakpoint) {
    width: 100%;
    padding-bottom: 66.66%;
    position: static;

    .watermark {
      display: none;
    }
  }
}

// Hide inactive elements from ARIA
// @see https://web.dev/aria-hidden-focus/
.tns-item[aria-hidden=true] {
  visibility: hidden;
  & > a {
    visibility: hidden;
  }
  // @note: Absolutely positioned element within the container does not become
  //  visible when the parent transitions visibility state, so make it visible
  //  at all times.
  // @todo(mattr): Investigate why this occurs.
  .watermark {
    visibility: visible;
  }
}
