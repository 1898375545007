$bubble-bg: #fff;
$bubble-color: #000;
$cite-color: $bubble-color;

blockquote.speech-bubble {
  border: none;
  font-size: unset;
}

.speech-bubble {
  padding: 1.5rem 2rem;
  position: relative;
  background: $bubble-bg;
  color: $bubble-color;
  flex-grow: 1;

  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 24px solid $bubble-bg;
    border-right: 12px solid transparent;
    border-top: 12px solid $bubble-bg;
    border-bottom: 20px solid transparent;
    left: 0;
    bottom: -24px;
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.testimonial-wrapper cite {
  display: block;
  margin-top: 1.875rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: $cite-color;
}

@media screen and (max-width: 1040px) {
  .testimonials-component {
    margin: 0 1rem;
  }
}

@media screen and (max-width: $site-width) {
  .testimonials {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .speech-bubble {
    width: 90%;
    min-width: 300px;

    &:not(:first-child) {
      margin-top: 6rem;
      margin-left: 0;
    }
  }
}

.testimonial-wrapper {
  width: 32%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $site-width) {
    width: 90%;
    min-width: 300px;
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
