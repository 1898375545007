@import "../metrics";

/* Global */
/* -------------------------------------------------------------------------------------------- */

* {
  box-sizing: border-box;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

:root {
  $border-thin: 2px;
  font-size: calc(1rem);
  line-height: 1.5;
}

/* Set max width of 70 characters and exclude those that don't match */
* {
  max-width: $measure;
}

html,
body,
div,
header,
nav,
main,
section,
footer {
  max-width: none;
}

html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $font-color;
}

html, body {
  min-height: 100vh;
}

/* Elements */
/* -------------------------------------------------------------------------------------------- */

h1 {
  font-size: $s2;
}

h2, h3 {
  font-size: $s1;
}

h4 {
  font-weight: bold;
}

b, strong, .strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: $color-primary;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

ul, ol {
  padding-left: 1.1rem;
}

ul li {
  /* TODO: Why do I have to reset this? Why is it not inheriting */
  line-height: 1.5;
  font-size: 1rem;
}

ul.no-bullet {
  padding-left: 0;
  list-style-type: none;
  max-width: none;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #DBDBDB;
  width: 100%;
  max-width: 100%;
}

hr.border-2 {
  border-top: 2px solid $font-color;
}

hr.reverse {
  border-top: 1px solid #FFF;
}

hr.reverse-grey {
  border-top: 1px solid grey;
}

ul {
  list-style-type: disc;
}

blockquote {
  font-size: 1.2rem;
  border-left: 3px solid #000;
  padding-left: 1rem;
}

/* Forms */
/* -------------------------------------------------------------------------------------------- */

form.stack {
  // --space: $s1;
}

input, textarea, select {
  border:none;
  padding: 1rem 1.25rem;
  font-size: inherit;
  background: $field-background-color;
  border-radius: $border-radius;
  width: 100%;
}

textarea {
  height: 6rem;
  line-height: 1.5
}

textarea.large {
  height: 18rem;
  line-height: 1.5
}

input[type=radio], input[type=checkbox] {
  display: inline;
  border: 0px;
  width: 1em;
  height: 1em;
}

select, input[type=button], input[type=submit], input[type=reset] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input:disabled {
  background: #CFCFCF;
}

select {
  // TODO: below adds arrows back, need to review, also hack for gradient
  // https://www.filamentgroup.com/lab/select-css.html
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, $field-background-color 0%, $field-background-color 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}

button, input, select, input[type=button], input[type=submit], input[type=reset] {
  font-size: inherit;
}

label, input, textarea {
  display: block;
}

label {
  font-weight: bold;
}

label + * {
  margin-top: 0.5rem !important;
}

label.inline {
  display: inline;
  font-weight: normal;
}

label.inline.reversed {
  .with-icon {
    fill: none;
    stroke: #000;
  }

  &:hover .with-icon {
    color: white;

    svg {
      stroke: white;
    }
  }
}

label.inline.reversed.active {
  color: white;

  .with-icon {
    stroke: white;
  }
}

// Fieldset reset from here: https://thatemil.com/blog/2015/01/03/reset-your-fieldset/

legend {
  padding: 0;
  display: table;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

legend {
  font-weight: bold;
}

fieldset label {
  font-weight: bold;
}

.boolean-checkbox label {
  font-weight: normal;
  display: inline;
}


fieldset input + label:after {
  // https://css-tricks.com/injecting-line-break/
  content: "\A";
  white-space: pre;
}

.error, .field-errors {
  font-weight: normal;
  color: $color-error !important;
}

.field-hint {
  font-style: italic;
  font-weight: normal;
  color: $color-dark-grey;
}

.field_with_errors {
  display: inline;
}

/* Table */
/* -------------------------------------------------------------------------------------------- */

table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  max-width: none;
  border-top: 1px solid $color-mid-grey;
}

table.head\:30 th {
  width: 30%
}

table.head\:33 th {
  width: 33%
}

th, td {
  text-align: left;
  vertical-align: top;
  padding: 0.8em 0;
  border-bottom: 1px solid $color-mid-grey;
}

/* Video */
/* -------------------------------------------------------------------------------------------- */

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Image/ Slug */
/* -------------------------------------------------------------------------------------------- */

.wrapper {
  display: flex;
  justify-content: space-between;
}

.slug-actions {
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  input[type="submit"] {
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
  }

  input[type="submit"]:hover {
    color: #808285;
    text-decoration: none;
  }
}

/* Utility */
/* -------------------------------------------------------------------------------------------- */

.stack-element-margins h2, .stack-element-margins h3 {
  margin-top: 2rem !important;
}

.hidden {
  display: none !important;
}

[data-hide] {
  max-height: 0;
}

[data-target='show-hide.content'] {
  overflow: hidden;
}

.bg-white {
  background: #FFF;
}

.box-light-grey {
  background: #eee;
}

.box.box-no-border {
  border: none;
}


/* Misc */
/* -------------------------------------------------------------------------------------------- */

.modal__content {
  max-width: 70ch;
}

.ie-banner {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  align-items: center;

  .ie-modal {
    position: relative;
    background-color: white;
    padding: 1.5rem 5rem 1.5rem 2rem;

    button {
      position: absolute;
      top: 5.5%;
      right: 2%;
    }
  }

  p {
    max-width: none;
  }

  ul {
    padding-left: 32px;
  }
}
