@import "../metrics";

/* Stack */
/* -------------------------------------------------------------------------------------------- */

@mixin stack($space: $s1) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > *:not([type="hidden"]) + * {
    margin-top: $space;
  }
}

@mixin stack-element {
  & > *:not([type="hidden"]) + * {
    @content
  }
}

@mixin stack--recursive($space: $s1) {
  * + * {
    margin-top: $space;
  }
}

/* Box */
/* -------------------------------------------------------------------------------------------- */

@mixin box($space: $s1, $border: $border-thin solid) {
  padding: $space;
  border: $border;

  * {
    color: inherit;
  }
}
