.tool-item-card {
  small {
    font-size: 0.9375rem;
    color: rgba($font-color, 0.65);
  }

  .source {
    font-style: italic;
  }
}
