.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  margin-left: 1rem;
  align-items: center;
}

.counter__count {
  margin-bottom: .75rem;
  border: 1px solid #000;
  border-radius: 50%;
  text-align: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
