.grant-container-wrapper {
  box-sizing: border-box;
}

.grant-container {
  .section-padding:first-of-type {
    padding-top: 0;

    @media screen and (max-width: $site-width) {
      padding: 0 16px 1.5rem 16px;
    }
  }
}

.grant-rich-content {
  width: 60%;

  .section-padding {
    margin: 0 -1rem;
    padding: 1.5rem 1rem;

    @media screen and (max-width: $site-width) {
      margin: 0;
      padding: 1.5rem 0;
    }
  }
}

.grant-key-dates {
  margin-bottom: -0.85rem;
}

.grant-key-dates,
.grant-additional-info {
  width: 40%;
  float: right;
  clear: right;
}

.grant-rounds {
  margin-bottom: $s3;
}

.grant-pages,
.grant-content-panel {
  margin-bottom: $s1;
  border: 4px solid lightgray;
}

.grant-pages a:link {
  text-decoration: none;
}

.grant-pages li {
  padding: $st $s0;
  border-bottom: 1px solid lightgray;
}

.grant-content-panel h2 {
  padding: $s0 $s0;
}

.grant-content-panel .trix-content {
  padding: 0 $s0 $s0 $s0;
}

@media screen and (max-width: 700px) {
  .grant-container {
    display: flex;
    flex-direction: column;

    .section-padding:first-of-type {
      padding: $s2 16px;
    }
  }

  .grant-rich-content,
  .grant-key-dates,
  .grant-additional-info {
    width: auto;
    float: none;
  }

  .grant-rounds {
    margin-bottom: unset;
  }

  .grant-key-dates .event-info-wrapper,
  .grant-additional-info .event-info-wrapper {
    margin-left: unset;
    margin-top: $s3;
  }

  .grant-rich-content {
    order: 2;
  }

  .grant-key-dates {
    order: 1;
    margin-bottom: 0;
  }

  .grant-additional-info {
    order: 3;
  }
}

.grant-content__spacing {
  margin-bottom: auto !important;
}
