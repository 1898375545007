$color-primary: #000000;
$color-secondary: #FFF;

@import "base";
@import "components/news_items";

header[role=banner] .logo {
  margin-left: scaling-size(0.5, 1.5, rem);
}

header[role=banner] .logo svg.corporate-header-logo {
  width: scaling-size(150, 300, px);
  height: scaling-size(41, 60, px);
}

.cta-buttons {
  svg {
    fill: none;
    stroke: #000;
  }

  .button:hover {
    color: white;

    svg {
      fill: none;
      stroke: #fff;

      &.icon-fill {
        fill: #fff;
        stroke: none;
      }
    }
  }
}

.banner-bg-primary {
  @extend .background\:adelaide-grey-25;
}

.banner-bg-corporate {
  @extend .background\:adelaide-grey-25;
}

.box-primary {
  @extend .background\:adelaide-grey-25;
}

.card--primary-background {
  @extend .background\:adelaide-grey-25;
}
