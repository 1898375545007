.options-dropdown {
  border: 1px solid #000;
  border-top: none;

  &:empty {
    display: none;
  }
}

.options-dropdown--option {
  padding: .25rem .5rem;
  width: 100%;

  &:hover {
    background: $color-primary;
  }
}
