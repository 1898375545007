.pagination {
  display: flex;
  justify-content: center;

  .page {
    margin: 0 .25rem;
    font-weight: bold;
    text-align: center;
    background: #fff;

    display: inline-flex;
    justify-content: center;

    > a {
      padding: .25rem 1rem;
      min-width: 2rem;
      border: 2px solid #000;

      display: block;
      overflow:auto;
      width: 100%;
      height: 100%;
    }

    &:not(.disabled):hover {
      background: #000;
      color: #fff;
      cursor: pointer;
      > a {
        color: #fff;
      }
    }
  }

  .active {
    background: $color-primary;
    color: $color-secondary;
    padding: .25rem 1rem;
    min-width: 2rem;
    border: 2px solid #000;
  }

  .disabled {
    opacity: .33;
    padding: .25rem 1rem;
    min-width: 2rem;
    border: 2px solid #000;
    display: none;
  }
}

