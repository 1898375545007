.version-banner {
  border-bottom: 1px solid black;
}

.version-banner.old {
  background-color: lightgray;
}

.version-banner.published_no_changes {
  background-color: $color-light-green;
}

.version-banner.published_has_changes {
  background-color: $color-light-yellow;
}

.version-banner.draft {
  background-color: $color-light-yellow;
}

.version-banner--content {
  height: $s5;
  margin: 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.version-banner--text--caption {
  font-weight: bold;
  font-size: larger;
}

.version-banner--text--nav-links {
  & > * {
    margin-left: 1rem;
  }
}

.version-banner--actions {
  display: flex;
  & > * + * {
    margin-left: .5rem;
  }
}
