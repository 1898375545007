//
// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
@import "~trix/dist/trix";

// Hide H1 button unless it's in use (backwards compatibility)
trix-toolbar .trix-button--icon-heading-1 {
  display: none;

  &.trix-active {
    display: block;
  }
}

trix-toolbar .trix-button--icon-heading-2::before {
  // Icon designed by Katalyst
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M2%203h20v5H2zM2%2010h16v2H2zM2%2014h18v2H2zM2%2018h13v2H2z%22%2F%3E%0A%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-heading-3::before {
  // Icon designed by Katalyst
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M2%207h20v5H2zM2%203h16v2H2zM2%2014h18v2H2zM2%2018h13v2H2z%22%2F%3E%0A%3C%2Fsvg%3E);
}

@mixin trix-attachment-icon($extension: "file") {
  &::before {
    display: inline-block;
    content: "";
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.7em;
    flex-shrink: 0;
    background-image: url('../images/file-type-icons/#{$extension}.svg');
  }
}

@mixin trix-attachment-icon-for($extension) {
  &::before {
    background-image: url('../images/file-type-icons/#{$extension}.svg');
  }
}

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    margin: 0 -0.5em;

    // revert upstream rules
    .attachment {
      padding: unset;
      flex-basis: unset;
      max-width: unset;
    }

    // set for direct children only
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      // revert upstream rules
      .attachment {
        flex-basis: unset;
        max-width: unset;
      }

      // set for direct children only
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  .attachment--document {
    display: flex;
    flex-direction: row;
    margin-top: $s0;

    @include trix-attachment-icon;

    a, .link {
      color: #000;
      text-decoration: underline;
    }

    a:hover {
      color: #808285;
      text-decoration: none;
    }
  }

  .attachment--pdf {
    @include trix-attachment-icon-for('pdf');
  }

  .attachment--zip {
    @include trix-attachment-icon-for('zip');
  }

  .attachment--ppt,
  .attachment--pptx,
  .attachment--pptm {
    @include trix-attachment-icon-for('ppt');
  }

  .attachment--doc,
  .attachment--docx,
  .attachment--docm {
    @include trix-attachment-icon-for('doc');
  }
}

.trix-content {
  max-width: $measure;

  h1, h2 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.3;
  }

  figure.attachment {
    text-align: left;
  }

  cta {
    display: block;
    margin: 1em 0;
  }

  cta > a[href] {
    outline: 2px solid black;
    padding: 0.25em 1em;

    br {
      display: none;
    }
  }
}


// Trix Editor Customisations for Documents (abusing preview to edit caption)
trix-editor {
  .attachment[data-trix-content-type^="application/"] {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: $s0;

    @include trix-attachment-icon;

    img {
      display: none;
    }

    .attachment__caption, .attachment__caption-editor {
      flex-grow: 1;
      text-align: left;
    }
  }

  .attachment[data-trix-content-type="application/pdf"] {
    @include trix-attachment-icon-for('pdf')
  }

  .attachment[data-trix-content-type="application/zip"] {
    @include trix-attachment-icon-for('zip')
  }

  .attachment[data-trix-content-type="application/doc"],
  .attachment[data-trix-content-type="application/ms-doc"],
  .attachment[data-trix-content-type="application/msword"],
  .attachment[data-trix-content-type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] {
    @include trix-attachment-icon-for('doc')
  }

  .attachment[data-trix-content-type="application/mspowerpoint"],
  .attachment[data-trix-content-type="application/powerpoint"],
  .attachment[data-trix-content-type="application/vnd.ms-powerpoint"],
  .attachment[data-trix-content-type="application/x-mspowerpoint"],
  .attachment[data-trix-content-type="application/vnd.openxmlformats-officedocument.presentationml.presentation"] {
    @include trix-attachment-icon-for('ppt')
  }
}
