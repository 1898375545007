.list--ruled {
  li {
    border-top: 1px solid #000;
    padding: 1.5rem 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
