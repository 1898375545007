$feature-banner-breakpoint: 860px;

.feature-banner {
  border-bottom: 1px solid $color-mid-grey;
  position: relative;
}

.feature-banner__image {
  background-size: cover;
  background-position: center;

  @include media-query($feature-banner-breakpoint) {
    padding-top: 50%;
  }

  @include media-query($feature-banner-breakpoint, min) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 50%;
  }
}

// https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items

.feature-banner__content-wrapper {
  @include media-query($feature-banner-breakpoint, min) {
    display: flex;
    flex-direction: column;
  }
}

.feature-banner__content {
  @include media-query($feature-banner-breakpoint) {
    padding: $s2 0;
  }

  @include media-query($feature-banner-breakpoint, min) {
    width: 50%;
    margin-left: auto;
    padding: $s4 0 $s4 $s3;
    min-height: 45rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.feature-banner__content--centered {
  @include media-query($feature-banner-breakpoint, min) {
    justify-content: center;
  }
}

.feature-banner__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-banner__icon {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin-left: $s1;
}

.feature-banner-component-full-width {
  width: unset;
  padding: 0 6.8rem;

  @media screen and (max-width: 40em) {
    padding: 0 1rem;
  }
}
