.hit-full {
  position: relative;

  a {
    box-shadow: none;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      outline: none;
    }
  }

  &:focus-within {
    @include custom-outline;
  }
}
