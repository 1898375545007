.color-speaker-name {
  color: #999999;
}

.color-white {
  color: white !important;
}

.bg-primary-color {
  background-color: $color-primary;
}

.bg-blue {
  background-color: #4B8BCB !important;
}
