.carousel-projects {
  position: relative;

  .tns-controls {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    box-shadow:none; // Hide outline as stretches whole box

    button {
      pointer-events: auto;
    }
  }
}
