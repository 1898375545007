/* Slide menu */
/* -------------------------------------------------------------------------------------------- */

.slide-menu {
  position: fixed;
  z-index: 2500; // Needs to be higher than .leaflet-top, .leaflet-bottom (1000) and .slide-menu__modal (2000)
  top: 0;
  left: 0;
  height: 100%;
  width: 35rem;
  transform: translateX(-100%);
  visibility: hidden;
  overflow-x: hidden;
  transition: .5s;
  white-space: nowrap;
  color: #000;
  background-color: #FFF;

  @include media-query(45rem) {
    width: 100%;
    left: 0;
  }
}

.slide-menu--open {
  visibility: visible;
  transform: translateX(0);
  box-shadow: -0.5rem 0px 5px rgba(#E0E0E0, 1);
}

button.slide-menu__button--open {
  display: block;
  padding: scaling-size(1, 1.7, rem);
  border: none;
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary;
    svg {
      fill: #000;
      stroke: #000;
    }
  }
}

button.slide-menu__button--close {
  display: block;
  width: 100%;
  padding: 1.7rem;
  border: none;
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary;
  }
}

// Slide menu modal

.slide-menu__modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(0, 0, 0, .6);
}

.slide-menu__modal--open {
  display: block;
}

