/* Main navigation */
/* -------------------------------------------------------------------------------------------- */

// Nav list

.main-nav,
.main-links-nav {
  padding: 2rem;
}

.main-nav ul,
.main-links-nav ul {
  padding: 0;
}

.main-nav ul ul > li,
.main-links-nav ul ul > li {
  margin-left: 2rem;
}

.main-nav ul,
.main-links-nav ul {
  margin: 0;
  list-style: none;
}

.main-nav ul > li {
  border-bottom: 1px solid #EDEDED;
}

.main-links-nav ul > li {
  padding-bottom: 8px;
  font-weight: bold;
}

// Nav font

.main-nav a,
.main-nav label {
  display: block;
  padding: 0.625rem 0;
  text-decoration: none;
  font-size: 1.125rem;
}

.main-nav a:hover {
  color: $font-color;
  font-weight: bold;
}

// Active state

.main-nav li.active {
  padding-bottom: 0;
  border: none;
}

.main-nav li.active > a, .main-nav li.active > div {
  display: block;
  border-bottom: 5px solid $color-primary;
  font-weight: bold;
  text-decoration: none;
}

// Nav with children

.split {
  display: flex !important;
  justify-content: space-between;

  > :first-child {
    flex-grow: 1;
  }
}

/* Shortcuts menu */
/* -------------------------------------------------------------------------------------------- */

$top-nav-breakpoint: 1315px;

.nav-shortcuts {
  margin-right: scaling-size(1, 2, rem);
}

.nav-shortcuts ul {
  max-width: none;
  margin: 0;
}

.nav-shortcuts li > a {
  color: #000;
  text-decoration: none;
}

.nav-shortcuts li > a:hover {
  border-bottom: 5px solid $color-primary
}

.nav-shortcuts li.active > a {
  border-bottom: 5px solid $color-primary
}

.nav-shortcuts li > a.no-hover:hover {
  border-bottom: none;
}

.nav-shortcuts li a.button {
  background-color: $color-primary;
  border: none;
  font-weight: normal;

  &:hover {
    color: white;
    background: #000;
  }
}

li.nav-shortcuts__with-icon {
  transition: opacity 0.25s;

  > a {
    display: block;
  }

  &:hover {
    opacity: 0.6;
  }
}

li.divider {
  border-right: 2px solid #979797;
}

.nav-shortcuts__list-item,
li.divider {
  @include media-query($top-nav-breakpoint) {
    display: none;
  }
}

a[data-target] {
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.icon-open {
  display: unset;
}

.icon-close {
  display: none;
}

[data-target][data-toggle-active] {
  .icon-open {
    display: none;
  }

  .icon-close {
    display: unset;
  }
}

.navigation-keyline {
  border-top: none;
  border-bottom: 5px solid $color-primary;
  // to allow margins
  width: unset;
  margin-top: 2.5rem;
}
